import { BadgeCheck, Search, ArrowRightLeft } from 'lucide-react'
import { type MetaFunction, Link } from 'react-router'
import { tv } from 'tailwind-variants'
import { Button } from '#app/components/ui/button'
import { bodySize } from '#app/utils'

export const meta: MetaFunction = () => {
	return [
		{ title: 'Home | Empirium' },
		{
			name: 'description',
			content:
				'A trusted B2B/B2G marketplace hosting everything from raw materials to finished goods, including manufacturing and financial services, serving allied defense, aerospace, transportation, and energy sectors.',
		},
	]
}

function ModernJourney({
	title,
	icon,
	iconColor,
	description,
}: {
	title: string
	icon: JSX.Element
	iconColor: 'blue' | 'green' | 'purple'
	description: string
}) {
	const color = tv({
		variants: {
			iconColor: {
				blue: 'text-blue-500',
				green: 'text-green-500 dark:text-green-700',
				purple: 'text-purple-500',
			},
		},
	})

	return (
		<div className="grid max-w-60 grid-cols-1 place-items-center gap-3 self-start text-center">
			<div className={color({ iconColor })}>{icon}</div>
			<div>
				<h2 className="mb-4 inline-block w-4/5 border-b border-solid border-b-white pb-2 font-michroma text-3xl dark:border-b-zinc-600 md:pb-6">
					{title}
				</h2>
				<p className="text-balance text-lg text-zinc-200 dark:text-zinc-600">
					{description}
				</p>
			</div>
		</div>
	)
}

export default function Index() {
	return (
		<>
			<section
				className={bodySize({
					class:
						'relative flex flex-col justify-between gap-4 pt-16 md:flex-row md:pt-24',
				})}
			>
				<div className="flex w-full flex-col md:items-center">
					<h2 className="font-michroma text-4xl leading-[4rem] md:text-center md:text-6xl md:leading-[5rem]">
						Secure <br /> Your Critical <br /> Supply Chain
					</h2>
					<div className="mb-8 mt-14 text-xl text-zinc-600 dark:text-zinc-400 md:w-3/5 md:text-center md:text-2xl">
						<p className="mb-4 text-balance">
							Trade everything from compliant raw materials to critical goods
							and services in a trusted global marketplace.
						</p>
					</div>
					<Link
						to="/subscribe"
						prefetch="intent"
						// onClick={() =>
						// gtag.event({
						// 	action: 'get_started',
						// 	category: 'get started',
						// 	label: 'clicked get started',
						// })
						// }
					>
						<Button
							className="pressed:bg-orange-500 inline-block bg-orange-600 font-sans text-sm uppercase text-white transition-colors duration-300 hover:bg-orange-900"
							size="lg"
						>
							Join Waitlist
						</Button>
					</Link>
				</div>
			</section>

			<section
				className={bodySize({
					fullSize: true,
					color: 'white',
					class:
						'mt-32 bg-zinc-800 py-14 text-white dark:bg-white dark:text-zinc-800',
				})}
			>
				<div
					className={bodySize({
						class:
							'grid grid-cols-1 place-items-center gap-6 py-20 md:grid-cols-3',
					})}
				>
					<h2 className="mb-10 text-center font-michroma text-4xl md:col-span-3 md:text-5xl md:leading-[5rem]">
						Trade Faster with Trust & Confidence
					</h2>
					<ModernJourney
						title="Apply"
						iconColor="blue"
						icon={<BadgeCheck size={64} />}
						description="Complete our onboarding process to setup an account."
					/>
					<ModernJourney
						title="Connect"
						iconColor="purple"
						icon={<Search size={64} />}
						description="Access a global network of verified buyers and sellers."
					/>
					<ModernJourney
						title="Trade"
						iconColor="green"
						icon={<ArrowRightLeft size={64} />}
						description="Buy, sell, and ship goods anywhere in the world."
					/>
				</div>
			</section>
		</>
	)
}
