import { useEffect } from 'react'
import {
	type LoaderFunctionArgs,
	type LinksFunction,
	Links,
	Meta,
	Outlet,
	Scripts,
	ScrollRestoration,
	useLoaderData,
	useLocation,
} from 'react-router'
import { HoneypotProvider } from 'remix-utils/honeypot/react'
import { Toaster } from '#app/components/ui/toaster.tsx'
import {
	type Cart,
	MarketplaceProvider,
} from '#app/context/marketplace-provider'
import { SendbirdProviderWrapper } from '#app/context/sendbird-provider-wrapper'
import { getUserByWorkOSID } from '#app/models/user.server'
import { getUserId } from '#app/services/auth.server'
import { getSession } from '#app/services/session.server'
import stylesheet from '#app/tailwind.css?url'
import { ClientHintCheck, getHints } from '#app/utils/client-hints.tsx'
import fetchCart from '#app/utils/crystallize/read/fetchCart.ts'
import { prisma } from '#app/utils/db.server'
import { getEnv } from '#app/utils/env.server.ts'
import * as gtag from '#app/utils/gtags.client'
import { honeypot } from '#app/utils/honeypot.server.ts'
import { getDomainUrl } from '#app/utils/misc'
import { createSendbirdUser } from '#app/utils/sendbird/create-sendbird-user.server'
import { useNonce } from '#app/utils/strings/nonce-provider.ts'
import { getTheme } from '#app/utils/theme.server'
import packageJson from '../package.json'
import { type loggerType } from '../server/utils/logger'
import { useTheme } from './hooks'

export const links: LinksFunction = () => [
	{ rel: 'preconnect', href: 'https://fonts.googleapis.com' },
	{ rel: 'preconnect', href: 'https://fonts.gstatic.com' },
	{
		rel: 'stylesheet',
		href: 'https://fonts.googleapis.com/css2?family=Michroma&family=Plus+Jakarta+Sans:ital,wght@0,200..800;1,200..800&display=swap',
		crossOrigin: 'anonymous',
	},
	{ rel: 'stylesheet', href: stylesheet, crossOrigin: 'anonymous' },
]

// Load the GA tracking id from the .env
export const loader = async ({ request, context }: LoaderFunctionArgs) => {
	const { logger } = context as { logger: loggerType }
	const session = await getSession(request.headers.get('cookie'))
	const cartId = session.get('cartId')
	const honeyProps = await honeypot.getInputProps()
	const workOSID = await getUserId(request)
	const user = workOSID ? await getUserByWorkOSID(workOSID) : null
	const ENV = getEnv()
	logger.info(`root info`, {
		workOSID,
		cartId,
		ip: request.headers.get('x-real-ip'),
		userId: user?.id,
		ENV,
	})

	let sendbirdUserId = user?.sendbirdID

	if (user?.id && !sendbirdUserId) {
		await createSendbirdUser({
			id: user?.id as string,
			name: user?.firstName + ' ' + user?.lastName,
		})

		sendbirdUserId = user?.id as string

		await prisma.user.update({
			where: {
				workOSID,
			},
			data: {
				sendbirdID: user?.id,
			},
		})
	}
	const response = await fetch(
		`https://api-${process.env.SENDBIRD_APPLICATION_ID}.sendbird.com/v3/users/${sendbirdUserId}/token`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json; charset=utf8',
				'Api-Token': process.env.SENDBIRD_API_TOKEN,
			},
			body: JSON.stringify({ user_id: sendbirdUserId }),
		},
	)
	const sendbirdToken = ((await response.json()) as { token: string }).token

	const ret = {
		initialCart: {},
		totalCartItems: 0,
		gaTrackingId: process.env.GA_TRACKING_ID,
		ENV,
		user,
		requestInfo: {
			hints: getHints(request),
			origin: getDomainUrl(request),
			path: new URL(request.url).pathname,
			userPrefs: {
				theme: getTheme(request),
			},
		},
		honeyProps,
		sendbirdToken,
		sendbirdUserId,
		sendbirdAppId: process.env.SENDBIRD_APPLICATION_ID,
	}

	if (!session || !cartId) {
		return ret
	}

	try {
		const cart: Cart = await fetchCart({
			cartId,
		})

		const totalCartItems =
			cart?.items?.reduce((acc, item) => acc + item.quantity, 0) || 0

		return {
			...ret,
			initialCart: cart,
			totalCartItems,
		}
	} catch (error: any) {
		// console.log('error', error)
		throw new Response((error && error?.errors?.[0]?.message) ?? error, {
			statusText: (error && error?.statusText) ?? 'Internal Server Error',
			headers: {
				cartId,
			},
			status: (error && error?.code) ?? 500,
		})
	}
}

function App() {
	const {
		initialCart,
		gaTrackingId,
		totalCartItems,
		ENV,
		sendbirdToken,
		sendbirdUserId,
		sendbirdAppId,
	} = useLoaderData<typeof loader>()
	const location = useLocation()
	const nonce = useNonce()
	const theme = useTheme()

	useEffect(() => {
		if (gaTrackingId?.length) {
			gtag.pageview(location.pathname, gaTrackingId)
		}
	}, [location, gaTrackingId])

	return (
		<html lang="en" className={theme}>
			<head>
				<ClientHintCheck nonce={nonce} />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1" />
				{process.env.NODE_ENV !== 'production' && (
					<meta name="robots" content="noindex" />
				)}
				<link
					rel="apple-touch-icon"
					sizes="180x180"
					href="/apple-touch-icon.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="32x32"
					href="/favicon-32x32.png"
				/>
				<link
					rel="icon"
					type="image/png"
					sizes="16x16"
					href="/favicon-16x16.png"
				/>
				<link rel="manifest" href="/site.webmanifest" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#4b4b49" />
				<meta name="msapplication-TileColor" content="#4b4b49" />
				<meta name="theme-color" content="#ffffff" />
				<Meta />
				<Links />
			</head>
			<body className="min-h-full">
				{/* Removing GTM while troubleshooting hydration errors */}
				{/* {process.env.NODE_ENV !== 'production' || !gaTrackingId ? null : (
					<>
						<script
							async
							src={`https://www.googletagmanager.com/gtag/js?id=${gaTrackingId}`}
						/>
						<script
							async
							id="gtag-init"
							dangerouslySetInnerHTML={{
								__html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${gaTrackingId}');
                `,
							}}
						/>
					</>
				)} */}
				<script
					nonce={nonce}
					async
					dangerouslySetInnerHTML={{
						__html: `
						window.appVersion = '${packageJson.version}';
					`,
					}}
				/>
				<SendbirdProviderWrapper
					accessToken={sendbirdToken}
					userId={sendbirdUserId!}
					appId={sendbirdAppId}
					theme={theme}
				>
					<MarketplaceProvider
						initialState={{
							cart: initialCart,
							cartLoaded: !!initialCart,
							totalCartItems,
						}}
					>
						<Outlet />
					</MarketplaceProvider>
				</SendbirdProviderWrapper>
				<script
					nonce={nonce}
					dangerouslySetInnerHTML={{
						__html: `window.ENV = ${JSON.stringify(ENV)}`,
					}}
				/>
				<ScrollRestoration nonce={nonce} />
				<Scripts nonce={nonce} />
				<Toaster />
			</body>
		</html>
	)
}

export default function AppWithProviders() {
	const data = useLoaderData<typeof loader>()
	return (
		<HoneypotProvider {...data.honeyProps}>
			<App />
		</HoneypotProvider>
	)
}

// TODO: Add ErrorBoundary here
