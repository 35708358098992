import { SendBirdProvider } from '@sendbird/uikit-react'
import { type ReactNode } from 'react'
import { ClientOnly } from 'remix-utils/client-only'
import { type Theme } from '#app/utils/theme.server.ts'

export const SendbirdProviderWrapper = ({
	children,
	theme = 'light',
	...rest
}: {
	children: ReactNode
	appId: string
	userId: string
	accessToken: string
	theme: Theme
}) =>
	rest.userId ? (
		<ClientOnly fallback={children}>
			{() => (
				<SendBirdProvider theme={theme} {...rest}>
					{children}
				</SendBirdProvider>
			)}
		</ClientOnly>
	) : (
		children
	)
