import {
	type ReactNode,
	createContext,
	useContext,
	useEffect,
	useState,
} from 'react'

export interface AddToCartItem {
	sku: string
	quantity: number
}

export interface Cart {
	id?: string
	state?: string
	customer?: Customer
	isExpired?: boolean
	expiresAt?: Date
	items?: Item[]
	total?: Total
}

export interface Customer {
	isGuest: boolean
}

export interface Item {
	name: string
	quantity: number
	context: Context
	price: ItemPrice
	variant: ItemVariant
}

export interface Context {
	taxRate: number
	origin: string
	managed: boolean
}

export interface ItemPrice {
	net: number
	gross: number
	taxAmount: number
}

export interface ItemVariant {
	sku: string
	name: string
	images: Image[]
	product: Product
	price: VariantPrice
}

export interface Image {
	url: string
	altText: string
	variants: VariantElement[]
}

export interface VariantElement {
	url: string
	altText: null
	key: string
	height: number
	width: number
}

export interface VariantPrice {
	gross: number
	net: number
}

export interface Product {
	path: string
	vatType: VatType
}

export interface VatType {
	percent: number
}

export interface Total {
	gross: number
	net: number
	taxAmount: number
	currency: string
	discounts: any[]
}

export interface MarketplaceState {
	addToCart: AddToCartItem | null
	setAddToCart: React.Dispatch<React.SetStateAction<AddToCartItem | null>>
	cart: Cart
	setCart: React.Dispatch<React.SetStateAction<Cart>>
	cartIsOpen: boolean
	setCartIsOpen: React.Dispatch<React.SetStateAction<boolean>>
	cartLoaded: boolean
	setCartLoaded: React.Dispatch<React.SetStateAction<boolean>>
	handleQuantityDecrease: (sku: string, quantity?: number) => Promise<void>
	handleQuantityIncrease: (sku: string, quantity?: number) => Promise<void>
	handlePullCart: () => Promise<void>
	totalCartItems: number
	updatingQuantity: boolean
	loading: boolean
	setLoading: React.Dispatch<React.SetStateAction<boolean>>
	error: string | null
	setError: React.Dispatch<React.SetStateAction<string | null>>
}

const MarketplaceContext = createContext<MarketplaceState | undefined>(
	undefined,
)

export const MarketplaceProvider = ({
	children,
	initialState,
}: {
	children: ReactNode
	initialState: Pick<MarketplaceState, 'cart' | 'cartLoaded' | 'totalCartItems'>
}) => {
	/*
	 * `addToCart` is Used as a list to keep track of the items that need to be add to the cart on crystallize
	 */
	const [addToCart, setAddToCart] = useState<AddToCartItem | null>(null)
	/*
	 * `cart` is the cart object that is returned from the shop api on crystallize
	 */
	const [cart, setCart] = useState<Cart>(initialState.cart)
	const [cartIsOpen, setCartIsOpen] = useState<boolean>(false)
	const [totalCartItems, setTotalCartItems] = useState<number>(
		initialState?.totalCartItems,
	)
	const [cartLoaded, setCartLoaded] = useState<boolean>(
		initialState?.cartLoaded,
	)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState<string | null>(null)
	const [updatingQuantity, setUpdatingQuantity] = useState(false)

	const handlePullCart = async () => {
		const res = await fetch('/api/cart')
		const cart = (await res.json()) as Cart
		setCart(cart)
	}

	const handleQuantityIncrease = async (sku: string, quantity: number = 1) => {
		console.log('handleQuantityIncrease', sku, quantity)
		setUpdatingQuantity(true)
		await fetch('/api/cart', {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ item: { sku, quantity } }),
		})
		await handlePullCart()
		setUpdatingQuantity(false)
	}

	const handleQuantityDecrease = async (sku: string, quantity: number = 1) => {
		console.log('handleQuantityDecrease', sku, quantity)
		setUpdatingQuantity(true)
		await fetch('/api/cart', {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ item: { sku, quantity } }),
		})
		await handlePullCart()
		setUpdatingQuantity(false)
	}

	useEffect(() => {
		setTotalCartItems(
			cart?.items?.reduce((acc, item) => acc + item.quantity, 0) || 0,
		)
	}, [cart])

	useEffect(() => {
		// Hydrate the cart when the cart is updated and loaded
		void (async () => {
			if (addToCart && addToCart?.quantity > 0) {
				setLoading(true)
				await fetch('/api/cart', {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ items: addToCart }),
				})

				await handlePullCart()
				setAddToCart(null)
				setLoading(false)
			}
		})()
	}, [addToCart])

	return (
		<MarketplaceContext.Provider
			value={{
				addToCart,
				setAddToCart,
				cart,
				setCart,
				cartIsOpen,
				setCartIsOpen,
				cartLoaded,
				setCartLoaded,
				handlePullCart,
				handleQuantityIncrease,
				handleQuantityDecrease,
				totalCartItems,
				updatingQuantity,
				loading,
				setLoading,
				error,
				setError,
			}}
		>
			{children}
		</MarketplaceContext.Provider>
	)
}

export const useMarketplaceState = () => {
	const context = useContext(MarketplaceContext)
	if (context === undefined) {
		throw new Error('useMarketplace must be used within a MarketplaceProvider')
	}

	return context
}
